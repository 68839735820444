<!--
Main Carousel
-->
<template>
  <!-- carousel -->
  <div class="" id="carousel-wrapper" v-if="this.data.length > 0">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in (this.data)" :key="index" class="main-carousel-swiper" :style="{ backgroundImage: 'url(' +  item.image + ')' }">
            <div class="container position-relative">
              <div class="position-absolute slide-text" :class="[item.bg == 0 ? 'b-blue' : 'b-white']">
              <div class="row">
                <div class="col-lg-5" :class="[item.bg == 0 ? 'text-white' : 'text-blue']">
                  <h1 class="carousel fw-bold">{{item.title}}</h1>
                  <div class="lead">{{item.lead}}</div>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                    <router-link :to="item.link" v-if="item.link" type="button" class="btn bg-yellow my-3 px-5 fw-medium">{{$t('modules.interested')}}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-main" slot="pagination"></div>
    </swiper>
    <!--carousel-->
  </div>
</template>

<script>

import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "module3",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    data: {data: [Array]}
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000
        },
        speed: 700
      }
    }
  }
}
</script>

<style scoped>

</style>
